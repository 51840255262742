.button {
    margin: .5em 0;
    margin-left: 1em;
}

.button i {
    color: #bebebe;
    cursor: pointer;
    font-size: 2em;
}

.button i:hover {
    color: #6C7AE0;
    transform: scale(1.05);
}