nav {
  display: flex;
  justify-content: center;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 0.875em;
}

.link {
  width: 130px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #bebebe;
  border-radius: .3rem;
  padding: .5em 1em;
  margin: 0 .8em 0 .8em;
  transition: all 0.3s ease-in-out;
}

.link:hover {
  background-color: #a7a7a7;
  scale: 1.02;
}

.active {
    width: 130px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #6C7AE0;
    border-radius: .3rem;
    padding: .5em 1em;
    margin: 0 .8em 0 .8em;
    transition: all 0.3s ease-in-out;
}

.admin {
  display: none;
}