/* Table styles */
.listElements {
    width: 100%;
    margin: 1em auto 2em;
}

.etable {
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    border-spacing: 0;
}
  
.table th, .table td {
    padding-right: 10px;
    font-weight: normal;
}

.table th:first-of-type {
    border-top-left-radius: .3em;
}

.table th:last-of-type {
    border-top-right-radius: .3em;
}
  
.table .columns {
    padding-left: 1em;
}

.table .columns:nth-child(1),
.table .columns:nth-child(2),
.table .columns:nth-child(3) {
    width: 10%;
    text-align: center;
}

.table .columns:nth-child(4) {
    width: 40%;

}

.table .columns:nth-child(5) {
    width: 20%;
    text-align: center;
}

.table .columns:nth-child(6) {
    width: 10%;
    text-align: center;
}

.table .rows.head th,
.table .rows td {
    padding-top: .5em;
    padding-bottom: .5em;
}

.table.ver3 tbody tr {
    border-bottom: 1px solid #e5e5e5;
}

.table.ver3 td {
    font-size: .875em;
    color: #808080;
}

.table.ver3 th {
    color: #fff;
    background-color: #6c7ae0;
}

.table.ver3 .rows:hover td {
    background-color: #fcebf5;
}

.table.ver3 .rows td:hover {
    background-color: #e03e9c;
    color: #fff;
}

.table.ver3 .rows td:hover a {
    color: #fff;
}

.table.ver3 td:last-child {
    text-align: center;
}

.table td a {
    text-decoration: none;
    color: #808080;
}

.table td i {
    margin: 0 .6em 0 .6em;
    cursor: pointer;
}

.table td:last-child i:hover {
    transform: scale(1.3);
}