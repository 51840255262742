.userdata {
    width: 90%;
    margin: 0 1.5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
}

.userdata .column p {
    width: 100%;
    padding-top: .2em;
    padding-bottom: .2em;
    display: grid;
    grid-template-columns: 3fr 5fr;
}

p span {
    padding: .3em 1em;
}

p span:nth-child(even) {
    background-color: #efefef;
}