.container {
    width: 100%;
    margin: 0 auto;
    font-size: .875em;
}

.container a {
    text-decoration: none;
}

.container .title {
    width: 90%;
    margin: 1em auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    /* align-items: center; */
}

.container h2 {
    width: 100%;
    margin: .5em auto;
}

.title .pdf {
    width: 100%;
    margin: 0 auto;
    align-items: center;
}

.pdf_container {
    width: 100%;
    margin: 1em auto;
    display: flex;
    justify-content: end;
    align-items: center;
}

.btn_pdf {
    text-decoration: none;
    width: 150px;
    margin-left: 2em;
    margin-bottom: 2em;
    padding: .5em 1em;
    border-radius: .3rem;
    background-color: #6C7AE0;
    color: white;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
}

.btn_pdf:hover {
    transform: scale(1.05);
}

.btn_pdf i {
    margin-right: .5em;
}

.container form {
    width: 90%;
    margin: 1em auto;
    display: grid;
    grid-template-columns: 1fr 4fr;
    row-gap: 1em;
}

.container form label {
    padding: .5em;
}

.container form input,
.container form textarea,
.container form select {
    padding: .5em;
    background-color: aliceblue;
    border-radius: .3rem;
    border: 1px solid #ccc;
}

.checking {
    margin: auto 0;
    width: 1.5rem;
    height: 1.5rem;
    cursor:pointer;
}

.container form input:focus,
.container form textarea:focus,
.container form select:focus {
    border: 1px solid #000;
    background-color: antiquewhite;
    border-radius: .3rem;
}

.btn_container {
    margin-top: 2em;
}

.btn_container .btn_add {
    width: 150px;
    margin: 0 auto;
    padding: .7em;
    border-radius: .3rem;
    background-color: #6C7AE0;
    color: white;
    font-size: 1em;
    cursor: pointer;
}

.btn_container .btn_add:hover {
    transform: scale(1.05);
}

/* Modal save correct or incorrect */
.disable {
    display: none;
}

/* .modal {
    position: fixed;
    bottom: 0;
    width: 100%;
} */