.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Filters and buttons styles */
.main {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.disable {
    display: none;
}

/* Multicalendar */
.main .multipleCalendar {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid antiquewhite;
}
 
.main .multipleCalendar .calendar div span {
    font-size: 0.875em;
}

.info {
    font-size: 0.8rem;
}

#info1 {
    color: #57CC99;
}

#info2 {
    color: #ffba08;
    padding-left: 1em;
}

#info3 {
    color: #dc2f02;
    padding-left: 1em;
}

/* Multiselector styles */
.filters {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 4em;
    row-gap: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.5em;
}

.datapicker {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Button */
.button {
    display: grid;
    grid-template-columns: 10fr 2fr;
    grid-template-rows: 1fr;
    border-top: 1px solid antiquewhite;
}


.button .pagination {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: .5em;
}

.button .pagination button {
    margin-right: 1em;
    width: 45px;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: .3rem;
    color: #fff;
    background-color: #6c7ae0;
}

.button .pagination p {
    font-size: .875em;
    color: #808080;
    margin-right: 1em;
    margin-top: 0;
    margin-bottom: 0;
}

.button .pagination .view {
    font-size: .875em;
    color: #808080;
}

.button .buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: .5em;
}

/* Table styles */
.listElements {
    width: 100%;
    margin: 1em auto 2em;
    font-size: 0.875em;
}

.etable {
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    border-spacing: 0;
}
  
.table th, .table td {
    padding-right: 10px;
    font-weight: normal;
    height: 40px;
}

.table th:first-of-type {
    border-top-left-radius: .3em;
}

.table th:last-of-type {
    border-top-right-radius: .3em;
}
  
.table .columns {
    width: 100%;
    padding-left: 1em;
}

.table .columns:nth-child(1) {
    width: 5%;
    text-align: center;
}

.table .columns:nth-of-type(2),
.table .columns:nth-of-type(3) {
    width: 10%;
    text-align: center;
}

.table .columns:nth-of-type(4) {
    width: 15%;
    text-align: start;
    padding-left: 2em;
}

.table .columns:nth-of-type(5),
.table .columns:nth-of-type(6) {
    width: 15%;
    text-align: center;
}

.table .columns:nth-of-type(7) {
    width: 16%;
    text-align: center;
}

.table .columns:nth-of-type(8),
.table .columns:nth-of-type(9) {
    width: 7%;
    text-align: center;
}

.table .rows.head th,
.table .rows td {
    padding-top: .5em;
    padding-bottom: .5em;
}

.table.ver3 tbody tr {
    border-bottom: 1px solid #e5e5e5;
}

.table.ver3 td {
    font-size: .875em;
    color: #808080;
}

.table.ver3 th {
    color: #fff;
    background-color: #6c7ae0;
}

.table.ver3 .rows:hover td {
    background-color: #fcebf5;
}

.table.ver3 .rows td:hover {
    background-color: #e03e9c;
    color: #fff;
}

.table.ver3 .rows td:hover a {
    color: #fff;
}

.table.ver3 td:last-child {
    text-align: center;
}

.table td a {
    text-decoration: none;
    color: #808080;
}

.table td i {
    margin: 0 .6em 0 .6em;
    cursor: pointer;
}

.table td:last-child i:hover {
    transform: scale(1.3);
}

.noactive {
    display: none;
}

.icon {
    display:block;
}