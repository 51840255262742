.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Filters and buttons styles */
.main {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.disable {
    display: none;
}

/* Multiselector styles */

/* Button */
.button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-top: 1px solid antiquewhite;
}

.button .pagination {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: .5em;
}

.button .pagination button {
    margin-right: 1em;
    width: 45px;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: .3rem;
    color: #fff;
    background-color: #6c7ae0;
}

.button .pagination button:hover {
    background-color: #305e90;
}

.button .pagination p {
    font-size: .875em;
    color: #808080;
    margin-right: 1em;
    margin-top: 0;
    margin-bottom: 0;
}

.button .pagination .view {
    font-size: .875em;
    color: #808080;
}

.button .buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: .5em;
}

/* Table styles */
.listElements {
    width: 100%;
    margin: 1em auto 2em;
}

.etable {
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    border-spacing: 0;
}
  
.table th, .table td {
    padding-right: 10px;
    font-weight: normal;
}

.table th:first-of-type {
    border-top-left-radius: .3em;
}

.table th:last-of-type {
    border-top-right-radius: .3em;
}
  
.table .columns {
    padding-left: 1em;
}

.table .columns:nth-child(1) {
    width: 5%;
    text-align: center;
}

.table .columns:nth-child(2) {
    width: 45%;
    text-align: start;
    padding-left: 2em;
}

.table .columns:nth-child(3),
.table .columns:nth-child(4) {
    width: 20%;
    text-align: center;
    padding-left: 2em;
}

.table .columns:nth-child(5) {
    width: 10%;
    text-align: center;
}

.table .rows.head th,
.table .rows td {
    padding-top: .5em;
    padding-bottom: .5em;
}

.table.ver3 tbody tr {
    border-bottom: 1px solid #e5e5e5;
}

.table.ver3 td {
    font-size: .875em;
    color: #808080;
}

.table.ver3 th {
    color: #fff;
    background-color: #6c7ae0;
}

.table.ver3 .rows:hover td {
    background-color: #fcebf5;
}

.table.ver3 .rows td:hover {
    background-color: #e03e9c;
    color: #fff;
}

.table.ver3 .rows td:hover a {
    color: #fff;
}

.table.ver3 td:last-child {
    text-align: center;
}

.table td a {
    text-decoration: none;
    color: #808080;
}

.table td i {
    margin: 0 .6em 0 .6em;
    cursor: pointer;
}

.table td:last-child i:hover {
    transform: scale(1.3);
}

/* Styles for the file upload button */
.buttons {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    
    align-items: center;
}

.uploadForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.uploadInput {
    width: 70%;
    padding: 0.3em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
}

.uploadButton {
    width: 45px;
    padding-top: .5em;
    padding-bottom: .5em;
    margin: 0 auto;
    border-radius: .3em;
    background-color: #6C7AE0;
    color: white;
    cursor: pointer;
}

.uploadButton:hover {
    background-color: #0056b3;
}

input[type="file"] {
    display: none; /* Oculta el input original */
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: .3em;
}

.custom-file-upload:hover {
    background-color: #0056b3;
}

.uploadButton1 {
    width: 45px;
    text-align: center;
    padding-top: .5em;
    padding-bottom: .5em;
    margin: 0 auto;
    border-radius: .3em;
    background-color: #6C7AE0;
    color: white;
    cursor: pointer;
}

.uploadButton1:hover {
    background-color: #305e90;
}

.spantext {
    width: 400px;
    padding: .5em;
    border-radius: .3em;
    font-size: 1em;
    background-color: #EFEFEF;
    color: #808080;
    margin: 0 auto;
    position: relative;
}

.spantext i {
    position: absolute;
    right: 0;
    top: 12px;
    margin-right: 1em;
    color: #808080;
}

.spantext i:hover {
    color: red;
    cursor: pointer;
}