.container {
    width: 100%;
    margin: 0 auto;
    font-size: 0.875em;
}

.container h2 {
    width: 90%;
    margin: 1em auto;
}

.container form {
    width: 90%;
    margin: 1em auto;
    display: grid;
    grid-template-columns: 1fr 4fr;
    row-gap: 1em;
}

.container form label {
    padding: .5em;
}

/* .container form input, */
.container form textarea,
.container form select {
    width: 100%;
    padding: .5em;
    background-color: aliceblue;
    border-radius: .3rem;
    border: 1px solid #ccc;
}

.checking {
    margin: auto 0;
    width: 1.5rem;
    height: 1.5rem;
    cursor:pointer;
}

.container form input:focus,
.container form textarea:focus,
.container form select:focus {
    border: 1px solid #000;
    background-color: antiquewhite;
    border-radius: .3rem;
}

.btn_container {
    margin-top: 2em;
}

.btn_container .btn_add {
    width: 150px;
    margin: 0 auto;
    padding: .7em;
    border-radius: .3rem;
    background-color: #6C7AE0;
    color: white;
    font-size: 0.875em;
    cursor: pointer;
}

.btn_container .btn_add:hover {
    transform: scale(1.05);
}

/* Modal save correct or incorrect */
.disable {
    display: none;
}