* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    /* position: relative; */

}

[data-theme='dark'] {
    --background: #05010a;
    --text-primary: #f2f2f2;
    --text-secondary: #a7a4a4;
    --accent: #6a5acd;
    --accent-hover: #5b4cbe; 
    --border: #696969;
    --shadow: 7px 15px 13px -4px #ffffff1b;
}

.bodyLogin {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
}

.containerLogin {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 90%;
    min-height: 450px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
}

.containerLogin p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.containerLogin span {
    font-size: 12px;
}

.containerLogin a {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;   
}

.containerLogin i {
    color: #333;
    font-size: 13px;
}

.containerLogin button {
    background-color:  #6C7AE0;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid #fff;
    border-radius: 8px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 10px;
}

.containerLogin .formLogin {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 auto;
}

.containerLogin input {
    width: 100%;
    background-color: #eee;
    border: none;
    margin: 5px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    outline: none;
}

.password_div {
    width: 100%;
    position:relative;
}

.password_div i {
    position: absolute;
    top: 40%;
    right: 5%;
    opacity: .4;
}

.formContainer {
    width: 100%;
    height: 100%;
    margin: 1em auto;
}

.formContainer:nth-child(1) {
    left: 0;
    width: 80%;
    z-index: 1;
}

.socialIcons {
    margin: 10px 0;
}

.socialIcons a {
    border: 1px solid #ccc;
    border-radius: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    width: 40px;
    height: 40px;
}

.toggleContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    border-radius: 150px 0 0 0;
    z-index: 1000;
    margin-top: 1.5em;
}

.toggle {
    width: 200%;
    height: 100%;
    background-color: #6C7AE0;
    background-color: linear-gradien(to right, #5c6bc0, #512da8);
    color: #fff;
    position: relative;
    transform: translateX(0);
}

.togglePanel {
    position: absolute;
    width: 50%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-inline-start: 0 30px;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
}

.togglePanel p {
    margin: 0 auto;
}

.togglePanel .hidden {
    margin-top: 2em;
}

.footer {
    padding: 10px 20px;
    text-align: center;
    font-size: 0.625rem;
    background-color: white;
    display: flex;
    justify-content: center;
  }
  
  .footer a {
    color: #c0c0c0;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

/* Pantalles desactivades */
.disable {
    display: none;
}

/* Tablet devices */
@media (min-width: 576px) {

    .bodyLogin {
        height: 100vh;
    }
    
    .containerLogin {
        background-color: #fff;
        border-radius: 30px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
        position: relative;
        overflow: hidden;
        width: 768px;
        max-width: 100%;
        min-height: 480px;
    }

    .containerLogin .formLogin {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 40px;
        height: 100%;
    }

    .containerLogin input {
        margin: 8px 0;
    }

    .formContainer {
        position: absolute;
        top: 0;
        margin: 0 auto;
    }

    .formContainer:nth-child(1) {
        width: 50%;
    }

    .socialIcons {
        margin: 20px 0;
    }

    .toggleContainer {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        border-radius: 150px 0 0 100px;
        margin-top: 0;
    }
    

}
/* Laptop devices */
@media (min-width: 768px) {

}

/* Desktop */
@media (min-width: 992px) {

}

/* Large devices */
@media (min-width: 1200px) {

}