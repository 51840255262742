.container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
}

.container .header_user {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.header_user .avatar {
    margin: 0 auto;
    width: 100%;
}

.header_user .menu_user {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.avatar img {
    width: 100%;
    padding: 2em;
}

.header_user h4 {
    text-align: center;
    margin: 0 auto;
}

.header_user .main_user {
    width: 100%;
}

.container .main_user .porlets_user {
    width: 100%;
    padding-top: 2em;
    display: flex;
    flex-direction: column;
}

.container .main_user .porlets_user .container_line {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1em;
    margin-bottom: 1em;
    gap: 5px;
}

.datas {
    width: 100%;
    padding-top: 1em;
    display: flex;
    justify-content: center;
}

/* Modal save correct or incorrect */
.disable {
    display: none;
}

/* Button */
.button {
    display: flex;
    justify-content: end;
    align-items: center;
    border-top: 1px solid antiquewhite;
}

/* Table styles */
.listElements {
    width: 100%;
    margin: 1em auto 2em;
}

/* Menú lateral */
.buttonLateral {
    width: 250px;
    padding-top: .5em;
    padding-bottom: .5em;
    margin: .35em auto;
    border-radius: .3em;
    background-color: #BEBEBE;
    color: white;
}

.menu_lateral {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu_user .upload {
    width: 100%;
    margin: 0 auto;
    padding-top: 3em;
}

.menu_user .upload .uploadmax {
    width: 250px;
    margin: 0 auto;
}

.buttonLateral2 {
    width: 250px;
    padding-top: .5em;
    padding-bottom: .5em;
    margin: .35em auto;
    border-radius: .3em;
    background-color: #6C7AE0;
    color: white;
}

.buttonLateral:hover {
    background-color: #a7a7a7;
    scale: 1.01;
    transition: background-color 0.5s ease, scale 0.5s ease;
}

.buttonLateral2:hover {
    scale: 1.01;
}

.uploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
}

.uploadInput {
    width: 250px;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
}

.uploadButton {
    width: 250px;
    padding-top: .5em;
    padding-bottom: .5em;
    margin: .35em auto;
    border-radius: .3em;
    background-color: #6C7AE0;
    color: white;
}

.uploadButton:hover {
    scale: 1.03;
}

.uploadInput[type="file"] {
    cursor: pointer;
    background-color: #f0f0f0;
    color: #333;
}

.uploadInput[type="file"]::-webkit-file-upload-button {
    background: #6C7AE0;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    cursor: pointer;
}

.uploadInput[type="file"]::-webkit-file-upload-button:hover {
    background: #5a68c7;
}

.uploadInput + .fileName {
    margin-top: 0.5em;
    font-size: 0.9em;
    color: #333;
}