* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.disable {
    display: none;
}

.visible {
    z-index: 10000;
}

#modal {
    margin: 1em;
}