.container_home {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding: 1em;
}

.container_line {
    display: flex;
    justify-content: flex-start;
    padding: 1em;
    gap: 20px;
    
}

.user_info {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: end;
}

.user_info .filter_year {
    width: 10%;
    display: flex;
    justify-content: end;
    align-items: center;
}

/* Movile devices */
@media (max-width: 960px) {

    .container {
        display: none;
    }

}