:root{
  font-size: .785;
  font-family: 'Inconsolata', monospace;
  --black: #000;
  --blue: #0d6ef2;
  --cyan: #0dcaf0;
  --green: #198754;
  --indigo: #6610f2;
  --orange: #fd7e14;
  --puerple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --teal: #20c997;
  --yellow: #ffc107;
  box-sizing: border-box;
}

*, ::before, ::after {
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  width: 100%;
}

body,
html,
#l_side,
#r_side,
section {
    height: 100%;
}