.container {
    width: 100%;
}

/* Filters and buttons styles */
.main {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.calendar {
    width: 95%;
    margin: 0 auto;
    font-size: 0.875em;
}

.disable {
    display: none;
}

/* Multiselector styles */
.filters {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 4em;
    row-gap: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.5em;
}

/* Button */
.menubuttons {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1em;
    display: flex;
    justify-content: end;
    border-top: 1px solid antiquewhite;
}

.menubuttons .button {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: .5em;
}