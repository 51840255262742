.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.headerSi {
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  background-color: #1e1e1e;
  color: #6b9a34;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.headerNo {
  display: none;
}

.logo {
  margin: auto 1em;
}

.logo img {
  height: 30px;
}

.logout {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 3em;
  color: white;
  text-decoration: none;
}

header i {
  cursor: pointer;
  padding-left: 1em;
}

.main {
  width: 100%;
  flex: 1;
  margin: 60px auto 0;
  height: 100vh;
}

.footer {
  padding: 10px 20px;
  text-align: center;
  font-size: 0.625rem;
  background-color: white;
  display: flex;
  justify-content: center;
  position: relative;
}

.footer a {
  color: #c0c0c0;
  text-decoration: none;
  display: flex;
  align-items: center;
}